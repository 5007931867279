import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
// import VueGtag from 'vue-gtag'
import config from '@/config'

if (config.buildMode === 'dev') {
    console.log('buildMode DEV')
    createApp(App)
    .use(createPinia())
    .use(router)
    .mount('#app')
} else {
    console.log('buildMode Prod')
    createApp(App)
    .use(createPinia())
    .use(router)
    // .use(VueGtag, {
    //         config: {
    //             id: 'G-P2JPHJB4FZ',  // Google Analytics의 Tracking ID를 넣어준다
    //         }
    //     },
    //     router
    // )
    .mount('#app')
}

import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [
  {
    path: "/",
    name: "Main",
    component: () => import("@/components/page/CustomerListPage.vue"),
  },
  {
    path: "/LoginPage",
    name: "LoginPage",
    component: () => import("@/components/page/LoginPage.vue"),
  },
  {
    path: "/consultPage",
    name: "ConsultPage",
    component: () => import("@/components/page/ConsultPage.vue"),
    children: [
      {
        path: "customerInfo",
        name: "CustomerInfo",
        component: () => import("@/components/contents/CustomerInfo.vue"),
      },
      {
        path: "workoutCareer",
        name: "WorkoutCareer",
        component: () => import("@/components/contents/WorkoutCareer.vue"),
      },
      {
        path: "workoutPurpose",
        name: "WorkoutPurpose",
        component: () => import("@/components/contents/WorkoutPurpose.vue"),
      },
      {
        path: "inbody",
        name: "Inbody",
        component: () => import("@/components/contents/Inbody.vue"),
      },
      {
        path: "painAndWorkout",
        name: "PainAndWorkout",
        component: () => import("@/components/contents/PainAndWorkout.vue"),
      },
      {
        path: "meal",
        name: "Meal",
        component: () => import("@/components/contents/Meal.vue"),
      },
      {
        path: "lifeStyleHabits",
        name: "LifeStyleHabits",
        component: () => import("@/components/contents/LifeStyleHabits.vue"),
      },
      {
        path: "registrationInfo",
        name: "RegistrationInfo",
        component: () => import("@/components/contents/RegistrationInfo.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
<template>
  <div>
    <router-view />
  </div>
</template>

<script setup></script>

<style>
@import "@/assets/css/normalization.css";
@import "@/assets/css/iconImage.css";

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
